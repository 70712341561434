import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import ClienteReducer from '../cliente/clienteReducer';
import FinanceiroTipoReducer from '../financeiroTipo/financeiroTipoReducer';
import EventoReducer from '../Evento/eventoReducer';
import ReservaReducer from '../reserva/reservaReducer'
import DespesaLancamentoReducer from '../depesaLancamento/despesaLancamentoReducer';
import PagamentoComissaoReducer from '../pagamentoComissao/pagamentoComissaoReducer';
import CadastroComissaoReducer from '../cadastroComissao/cadastroComissaoReducer';


const rootReducer = combineReducers({
	auth: AuthReducer,
    dashboard: DashboardReducer,
    usuario: UsuarioReducer,
	cliente: ClienteReducer,
    financeiroTipo: FinanceiroTipoReducer,
    evento: EventoReducer,
    reserva: ReservaReducer,
    despesaLancamento: DespesaLancamentoReducer,
    pagamentoComissao: PagamentoComissaoReducer,
    cadastroComissao: CadastroComissaoReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;
