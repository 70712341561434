import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function getLista (registro) {
    const request = axios.get(`${consts.API_URL}/cliente/agruparPorStatus`);
    return {
        type: 'DASHBOARD_LISTADO',
        payload: request
    };
}
export function getListaReserva (registro) {
    const request = axios.get(`${consts.API_URL}/reserva/agruparReservas`);
    return {
        type: 'DASHBOARD_LISTADO_RESERVA',
        payload: request
    };
}

export function setFiltro(filtro) {
    return {
        type: 'DASHBOARD_FILTOR',
        payload: filtro
    };
}
