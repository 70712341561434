import React from 'react'

export default props => (
    <footer className='main-footer'>
        <strong>
            Copyright &copy; {new Date().getFullYear()}
            <a href='' target='_blank'> Viva</a>.
        </strong>
    </footer>
)
