import React from 'react'
import Grid from '../layout/grid'
import Select from 'react-select'

export default props => (
    <Grid cols={props.cols}>
        <div className='form-group'>
			{props.name ? (
				<label htmlFor={props.name}>{props.label}</label>
			) : null}
			{props.onChange ? (
	            <Select
					readOnly={props.readOnly}
					defaultValue={props.value ? (props.options || []).map(opt => ({ value: String(opt.id), label: opt.valor })).filter(opt => opt.value == props.value)[0] || null : null}
					isClearable={true}
					isSearchable={true}
					onChange={(item) => props.onChange(item ? item.value : null)}
					options={(props.options || []).map(opt => ({ value: opt.id, label: opt.valor }))} />
		   ) : (
			   <Select
				   readOnly={props.readOnly}
				   isClearable={true}
				   isSearchable={true}
				   defaultValue={props.input.value ? (props.options || []).map(opt => ({ value: String(opt.id), label: opt.valor })).filter(opt => opt.value == props.input.value)[0] || null : null}
				   onChange={(item) => props.input.onChange(item ? item.value : null)}
				   onBlur={(value) => props.input.onBlur()}
				   options={(props.options || []).map(opt => ({ value: opt.id, label: opt.valor }))} />
		  )}
       </div>
   </Grid>
)
