import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './cadastroComissaoForm';
import DateFormat from '../common/dateFormat/DateFormat';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import FormatUtils from '../common/formatUtils/FormatUtils';



import {
	setModoTela, initForm, salvar, excluir, getLista, getListaRepresentante,setFiltro
} from './cadastroComissaoActions';

class CadastroComissao extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista();
        this.props.getListaRepresentante();

    }

    render() {
        return (
            <div>

				{this.props.aguardando ? (
					<div class='modal fade show' id='modal-overlay' style={{ display: 'block', paddingRight: 15 }} aria-modal='true' role='dialog'>
						<div class='modal-dialog'>
							<div class='overlay'>
								<i class='fas fa-4x fa-spinner fa-spin' style={{ marginLeft: '50%', marginRight: '50%' }}></i>
							</div>
						</div>
					</div>
				) : null}

                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		
		let lista = this.props.lista.filter(item => {
			return !this.state.pesquisar
				|| (` ${item.nome_representante}  ${item.percentual_comissao} `).toUpperCase().includes(this.state.pesquisar.toUpperCase());
		});

		
		

		return (
			<ContentCard>
				<BoxButtonHeader>
					<Button
						text='Adicionar'
						type={'success'}
						icon={'fa fa-plus'}
						style={{ position: 'fixed' }}
						event={() => {
							this.props.setModoTela('cadastro', {});
							this.props.initForm();
						}} />
				</BoxButtonHeader>

				<Row>
				
						
						<Grid cols='12 12 12 12'>
					
					<input className='form-control'
						placeholder='Pesquisar'
						type='text'
						value={this.state.pesquisar}
						onChange={e => {
							this.setState({ ...this.state, pesquisar: e.target.value });
						}} />
				</Grid>
				</Row>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Representante</Th>
                                <Th> Percentual Comissão</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome_representante}</Td>
									<Td>{FormatUtils.formatarValorTela( item.percentual_comissao)}%</Td>
                                    <Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fa fa-pencil'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
                                                  
												});
												this.props.initForm({
													...item,
                                               
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fa fa-trash-o'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
                                                
                                               
												});
												this.props.initForm({
													...item,
                                                  
                                               
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.cadastroComissao.modoTela,
	lista: state.cadastroComissao.lista,
	aguardando: state.cadastroComissao.aguardando,
	filtro: state.cadastroComissao.filtro,
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar, excluir, getLista, getListaRepresentante,setFiltro
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CadastroComissao);