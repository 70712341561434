import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import MenuItem from './menuItem'
import MenuTree from './menuTree'

import { getLista as getListaUsuario } from '../../usuario/usuarioActions';

class Menu extends Component {

	componentWillMount() {
        this.props.getListaUsuario(this.props.sessao.id_empresa);
    }

	render() {
		return (
		    <ul className='sidebar-menu'>
		        <MenuItem path='/' label='Dashboard' icon='dashboard' />

				{this.props.usuarioCarregado && this.props.usuarioCarregado.administrativo ? (
					<MenuTree label='Acesso' icon='user'>
			            <MenuItem path='usuario' label='Usuário' icon='' />
			        </MenuTree>
				) : null}

				{this.props.usuarioCarregado && this.props.usuarioCarregado.administrativo ? (
			        <MenuTree label='Cadastro' icon='edit'>
						<MenuItem path='cliente' label='Cliente' icon='' />
						<MenuItem path='convite' label='Convite' icon='' />
						<MenuItem path='relatorio' label='Relatório' icon='' />
						<MenuItem path='historico' label='Histórico' icon='' />

			        </MenuTree>
				) : null}

				<MenuItem path='reserva' label='Reserva' icon='calendar' />
				
				{this.props.usuarioCarregado && this.props.usuarioCarregado.administrativo ? (
			        <MenuTree label='Financeiro' icon=' fa fa-usd'>
						<MenuItem path='financeiroTipo' label='Receita e Despesa' icon='' />
						<MenuItem path='evento' label='Evento' icon='' />
						<MenuItem path='despesaLancamento' label='Despesa Lançamento' icon='' />
						<MenuItem path='cadastroComissao' label='Cadastro Comissão' icon='' />
						<MenuItem path='pagamentoComissao' label='Pagamento Comissão' icon='' />
						<MenuItem path='saldoRepresentante' label='Saldo Representante' icon='' />
					
					</MenuTree>
				) : null}

		    </ul>
		);
	}
}


const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: (state.usuario.lista || []).filter(item => item.id == state.auth.usuario.id)[0]
});
const mapDispatchToProps = dispatch => bindActionCreators({ getListaUsuario }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
