import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import Button from '../common/button/button';
import Form from './clienteForm';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import DateFormat from '../common/dateFormat/DateFormat';
import Switch from 'react-switch';
import Grid from '../common/layout/grid';
import ReactExport from 'react-export-excel-fixed-xlsx';

import {
	setModoTela, initForm, salvar, excluir, reservarConvite, getLista, setFiltro, getRegistro,
	getListaClienteTipo, getListaClienteStatus, getListaEstadoCivil, getListaFormaPagamento, getListaOperadoraTelefonica,
	getListaTipoContrato, getListaUsuario, getListaEstado, getListaCidade
} from './clienteActions';

class Convite extends Component {

	state = {
		pesquisar: '',
		criterio: true,
		mostraApp: true,
		mostraRealizado: true,
	}

	componentWillMount() {
		this.props.getLista(true);
		this.props.getListaClienteTipo();
		this.props.getListaClienteStatus();
		this.props.getListaEstadoCivil();
		this.props.getListaFormaPagamento();
		this.props.getListaOperadoraTelefonica();
		this.props.getListaTipoContrato();
		this.props.getListaUsuario();
		this.props.getListaEstado();
		this.props.getListaCidade();
	}

	render() {
		return (

			<div>
				<ContentHeader title='Convite' small='Cadastro' />
				<Content>
					{this.props.modoTela == 'visualizacao' ? (
						<Form visualizar />
					) : null}
					{this.props.modoTela == 'reservarConvite' ? (
						<Form reservarConvite onSubmit={this.props.reservarConvite} />
					) : null}
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
				</Content>
			</div>
		)
	}



	lista() {

		let lista = this.props.lista.filter(item => item.id_tipo == 2).filter(item => {
			if (this.state.criterio && (!this.state.pesquisar || this.state.pesquisar.length < 4)) {
				return false;
			}
			return !this.state.pesquisar
				|| (`${item.id_tipo == 1 ? item.numero_contrato : item.numero_convite} ${item.nome} ${item.cpf} ${item.telefone_fixo || ''} ${item.celular1 || ''} ${item.celular2 || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase());
		});

		let app = this.props.lista.filter(item => item.id_status == 4).length; //adicionado pelo app
		let realizado = this.props.lista.filter(item => item.id_status == 5).length; // Reserva Realizada
		let total = this.props.lista.length;

		// if (!this.state.mostraApp) {
		// 	console.log();
		// 	lista = lista.filter(item => item.id_status != 4);
		// }
		// if (!this.state.mostraRealizado) {
		// 	console.log();
		// 	lista = lista.filter(item => item.id_status != 5);
		// }

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		return (
			<><Row>
				<Grid cols='12 6 4 4'>
					<div class='info-box'>
						<span class='info-box-icon' style={{ backgroundColor: '#AE5BBD', color: '#fff' }}><i class='fa fa-mobile' aria-hidden='true'></i></span>
						<div class='info-box-content'>
							<span class='info-box-text'>Adicionados pelo App</span>
							<span class='info-box-number'>{app}</span>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<span class='info-box-number'>{total ? parseInt((app * 100) / total) : 0}<small>%</small></span>
								<Switch uncheckedIcon={null} checkedIcon={null} onChange={() => {

									let status = this.props.filtro.status;

									if (this.state.mostraApp) {
										status = status.filter(item => item != 4);
									} else {
										status.push(4);
									}
									this.setState({ ...this.state, mostraApp: !this.state.mostraApp });

									this.props.setFiltro({
										...this.props.filtro,
										status: status
									});
									this.props.getLista();

								}} checked={this.state.mostraApp} />
							</div>
						</div>
					</div>
				</Grid>

				<Grid cols='12 6 4 4'>
					<div class='info-box'>
						<span class='info-box-icon ' style={{ backgroundColor: '#357c3c', color: '#fff' }}><i class='fa fa-calendar-check-o'></i></span>
						<div class='info-box-content'>
							<span class='info-box-text'> Reserva Realizada</span>
							<span class='info-box-number'>{realizado}</span>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<span class='info-box-number'>{total ? parseInt((realizado * 100) / total) : 0}<small>%</small></span>
								<Switch uncheckedIcon={null} checkedIcon={null} onChange={() => {

									let status = this.props.filtro.status;

									if (this.state.mostraRealizado) {
										status = status.filter(item => item != 5);
									} else {
										status.push(5);
									}
									this.setState({ ...this.state, mostraRealizado: !this.state.mostraRealizado });

									this.props.setFiltro({
										...this.props.filtro,
										status: status
									});
									this.props.getLista();

								}} checked={this.state.mostraRealizado} />
							</div>
						</div>
					</div>
				</Grid>
			</Row>

				<ContentCard>

					<Row>
						<Grid cols='6 6 2 1'>
							<Button
								text='Adicionar'
								style={{ marginLeft: -15 }}
								type={'success'}
								icon={'fa fa-plus'}
								style={{ position: 'fixed' }}
								event={() => {
									this.props.setModoTela('cadastro', {
										id_tipo: 2,
										data_venda: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
									});
									this.props.initForm({
										id_tipo: 2,
										data_venda: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
									});
								}} />
						</Grid>
						<Grid cols='6 6 2 1'>
							<ExcelFile
								filename={'Convites'}
								element={
									<Button
										text='Exportar'
										type={'primary'}
										icon={'fa fa-download'} />
								}>
								<ExcelSheet data={lista} name='Convites'>
									<ExcelColumn label='Número' value='numero_convite' />
									<ExcelColumn label='Nome' value='nome' />
									<ExcelColumn label='Telefone' value='telefone_fixo' />
									<ExcelColumn label='Celular' value='celular1' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>
					</Row>
					<Row>
						<LabelAndInputMask
							label='Data Inicial'
							placeholder='Informe a Data Inicial'
							mask={'99/99/9999'}
							cols='12 4 3'
							value={this.props.filtro.dataInicial}
							onChange={data => {
								this.props.setFiltro({
									...this.props.filtro,
									dataInicial: data.target.value
								});
							}} />
						<LabelAndInputMask
							label='Data Final'
							placeholder='Informe a Data Final'
							mask={'99/99/9999'}
							cols='12 4 3'
							value={this.props.filtro.dataFinal}
							onChange={data => {
								this.props.setFiltro({
									...this.props.filtro,
									dataFinal: data.target.value
								});
							}} />
							<Grid cols='6 6 2 1'>
								<Button
									style={{ marginTop: 25 }}
									text='Atualizar'
									type={'primary'}
									icon={''}
									event={() => {
										this.props.getLista(true);
									}} />
							</Grid>
						</Row><Row>
						<Select
							name='id_vendedor'
							options={this.props.listaUsuario}
							label='Vendedor'
							cols='12 4 3'
							placeholder='Selecione o Vendedor'
							value={this.props.filtro.id_vendedor}
							onChange={value => {
								this.props.setFiltro({
									...this.props.filtro,
									id_vendedor: value
								});
								this.props.getLista(true);
							}} />

						<Select
							name='id_representante'
							options={this.props.listaUsuario}
							label='Representante'
							cols='12 4 3'
							placeholder='Selecione o Representante'
							value={this.props.filtro.id_representante}
							onChange={value => {
								this.props.setFiltro({
									...this.props.filtro,
									id_representante: value
								});
								this.props.getLista(true);
							}} />
						<Select
							name='id_cidade'
							options={this.props.listaCidade}
							label='Cidade'
							cols='12 4 3'
							placeholder='Selecione o Representante'
							value={this.props.filtro.id_cidade}
							onChange={value => {
								this.props.setFiltro({
									...this.props.filtro,
									id_cidade: value
								});
								this.props.getLista(false);
							}} />
					</Row>

					<Row>
						<Grid cols='8 8 8 9'>
							<input className='form-control'
								placeholder='Pesquisar (digite pelo menos 4 caracteres para iniciar a pesquisa)'
								type='text'
								value={this.state.pesquisar}
								onChange={e => {
									this.setState({ ...this.state, pesquisar: e.target.value });
								}} />
						</Grid>
						<Select
							options={[{ id: 1, valor: 'Listar pela pesquisa' }, { id: 2, valor: 'Listar todos' }]}
							label=''
							cols='4 4 4 3'
							placeholder='Selecione o critério'
							value={this.state.criterio ? 1 : 2}
							onChange={value => {
								this.setState({
									...this.state,
									criterio: value == 1
								});
							}} />
					</Row>
					<Table>
						<THead>
							<Tr>
								<Th>Data / Hora</Th>
								<Th alignCenter>Status</Th>
								<Th>Número</Th>
								<Th>Nome</Th>
								<Th acoes>Reserva</Th>
								<Th alignCenter acoes>Ações</Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => (
								<Tr key={item.id}>
									<Td>{DateFormat.formatarDataSqlParaTela(item.data_venda)}</Td>
									<Td alignCenter style={{ color: item.cor_status, fontWeight: 'bold' }}>{item.nome_status}</Td>
									<Td>{item.id_tipo == 1 ? item.numero_contrato : item.numero_convite}</Td>
									<Td>{item.nome}</Td>
									<Td>
										{DateFormat.formatarDataSqlParaTela(item.data_reserva_convite)}
										<ButtonTable
											type={'success'}
											icon={'fa fa-calendar'}
											event={() => {
												this.props.setModoTela('reservarConvite', {
													...item,
													data_reserva_convite: item.data_reserva_convite ? DateFormat.formatarDataSqlParaTela(item.data_reserva_convite) : null
												});
												this.props.initForm({
													...item,
													data_reserva_convite: item.data_reserva_convite ? DateFormat.formatarDataSqlParaTela(item.data_reserva_convite) : null
												});

												this.props.getRegistro(item.id);
											}} />
									</Td>
									<Td alignCenter>
										<ButtonTable
											type={'primary'}
											icon={'fa fa-eye'}
											event={() => {
												this.props.setModoTela('visualizacao', {
													...item,
													data_venda: DateFormat.formatarDataSqlParaTela(item.data_venda),
													data_nascimento: item.data_nascimento ? DateFormat.formatarDataSqlParaTela(item.data_nascimento) : null,
													itens: item.itens.map(itemPgto => ({
														...itemPgto,
														data_vencimento: DateFormat.formatarDataSqlParaTela(itemPgto.data_vencimento)
													}))
												});
												this.props.initForm({
													...item,
													data_venda: DateFormat.formatarDataSqlParaTela(item.data_venda),
													data_nascimento: item.data_nascimento ? DateFormat.formatarDataSqlParaTela(item.data_nascimento) : null,
													itens: item.itens.map(itemPgto => ({
														...itemPgto,
														data_vencimento: DateFormat.formatarDataSqlParaTela(itemPgto.data_vencimento)
													}))
												});

												this.props.getRegistro(item.id);
											}} />
										<ButtonTable
											type={'warning'}
											icon={'fa fa-pencil'}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													data_venda: DateFormat.formatarDataSqlParaTela(item.data_venda),
													data_nascimento: item.data_nascimento ? DateFormat.formatarDataSqlParaTela(item.data_nascimento) : null,
													itens: item.itens.map(itemPgto => ({
														...itemPgto,
														data_vencimento: DateFormat.formatarDataSqlParaTela(itemPgto.data_vencimento)
													}))
												});
												this.props.initForm({
													...item,
													data_venda: DateFormat.formatarDataSqlParaTela(item.data_venda),
													data_nascimento: item.data_nascimento ? DateFormat.formatarDataSqlParaTela(item.data_nascimento) : null,
													itens: item.itens.map(itemPgto => ({
														...itemPgto,
														data_vencimento: DateFormat.formatarDataSqlParaTela(itemPgto.data_vencimento)
													}))
												});

												this.props.getRegistro(item.id);
											}} />
										<ButtonTable
											type={'danger'}
											icon={'fa fa-trash-o'}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													data_venda: DateFormat.formatarDataSqlParaTela(item.data_venda),
													data_nascimento: item.data_nascimento ? DateFormat.formatarDataSqlParaTela(item.data_nascimento) : null,
													itens: item.itens.map(itemPgto => ({
														...itemPgto,
														data_vencimento: DateFormat.formatarDataSqlParaTela(itemPgto.data_vencimento)
													}))
												});
												this.props.initForm({
													...item,
													data_venda: DateFormat.formatarDataSqlParaTela(item.data_venda),
													data_nascimento: item.data_nascimento ? DateFormat.formatarDataSqlParaTela(item.data_nascimento) : null,
													itens: item.itens.map(itemPgto => ({
														...itemPgto,
														data_vencimento: DateFormat.formatarDataSqlParaTela(itemPgto.data_vencimento)
													}))
												});

												this.props.getRegistro(item.id);
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCard></>

		);
	}
}

function mapStateToProps(state) {
	return ({
		sessao: state.auth.usuario,
		modoTela: state.cliente.modoTela,
		lista: state.cliente.lista,
		filtro: state.cliente.filtro,
		listaUsuario: state.cliente.listaUsuario,
		listaCidade:state.cliente.listaCidade,
	});
}
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, reservarConvite, getLista, setFiltro, getRegistro, getListaClienteTipo, getListaClienteStatus, getListaEstadoCivil, getListaFormaPagamento, getListaOperadoraTelefonica, getListaTipoContrato, getListaUsuario, getListaEstado, getListaCidade }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Convite);
