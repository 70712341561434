import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ColorSelector from '../common/form/colorSeletor';
import FormatUtils from '../common/formatUtils/FormatUtils';

import {
	setModoTela, initForm, getListaRepresentante
} from './pagamentoComissaoActions';
import { selectTab } from '../common/tab/tabActions';
import labelAndInput from '../common/form/labelAndInput';
import labelAndInputDateTime from '../common/form/labelAndInputDateTime';
import NumberFormat from 'react-number-format';

class PagamentoComissaoForm extends Component {

	state = {

	}

	componentWillMount() {
		this.props.getListaRepresentante();

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';
		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>

							<Select
								name='id_representante'
								component={LabelAndInput}
								label='Representante'
								placeholder='Informe o Representante'
								cols='12 6 6 4'
								readOnly={readOnly}
								options={this.props.listaRepresentante}
								value={this.props.formularioValues.id_representante}
								onChange={(value) => {
									let representante = this.props.listaRepresentante.filter(item => item.id === value)[0];
									this.props.initForm({
										...this.props.formularioValues,
										id_representante: value,
										percentual: FormatUtils.formatarValorTela(representante ? representante.percentual_comissao || 0 : 0, 0)
									})
								}} />
						</Row>

						<Row>
							<Field
								name='percentual'
								component={LabelAndInputNumber}
								label='Percentual'
								placeholder='Informe o percentual'
								cols='12 6 4 3'
								value={this.props.formularioValues.percentual}
								onChange={(value) => {
									this.props.initForm({
										...this.props.formularioValues,
										percentual: value,
									})
								}}
							/>
						</Row>
						<Row>
							<Field
								name='valor_titulo'
								component={LabelAndInputNumber}
								label='Valor Título'
								placeholder='Informe o valor título'
								cols='12 6 4 3'
								readOnly={readOnly}
								value={this.props.formularioValues.valor_titulo}
								onChange={(value) => {
									let comissao = parseFloat(this.props.formularioValues.percentual) / 100 * (this.props.formularioValues.valor_titulo)
									this.props.initForm({
										...this.props.formularioValues,
										valor_titulo: value,
										valor_comissao: comissao
									})
								}} />
						</Row>
						<Row>
							<Field
								name='valor_comissao'
								component={LabelAndInput}
								label='Valor Comissao'
								placeholder='Informe a comissao'
								cols='6 6 6 6 '
								value={this.props.formularioValues.valor_comissao}
								onChange={(value) => {
									this.props.initForm({
										...this.props.formularioValues,
										valor_comissao: value,
									})
								}}
							/>

						</Row>

						<Row>

							<Field
								name='datahora_pagamento'
								component={labelAndInputDateTime}
								label='Data Pagamento'
								placeholder='Informe a data'
								cols='12 6 6 6'
							/>
						</Row>

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		)
	}


}

PagamentoComissaoForm = reduxForm({ form: 'pagamentoComissaoForm', destroyOnUnmount: false })(PagamentoComissaoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('pagamentoComissaoForm')(state),
	registro: state.pagamentoComissao.registro,
	listaRepresentante: state.pagamentoComissao.listaRepresentante,
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, getListaRepresentante
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PagamentoComissaoForm);