import React from 'react'
import Navbar from './navbar'
import imagemLogo from '../../assets/icone_128.png';

export default props => (
    <header className='main-header'>
        <a href='/#/' className='logo'>
            <span className='logo-mini'>
                <img src={imagemLogo} style={{ height: 40, width: 40 }} />
            </span>
            <span className='logo-lg'>
				<img src={imagemLogo} style={{ height: 40, width: 40 }} />
                <b> Viva - Admin</b>
            </span>
        </a>
        <nav className='navbar navbar-static-top'>
            <a href className='sidebar-toggle' data-toggle='offcanvas'></a>
			<Navbar />
        </nav>
    </header>
)
