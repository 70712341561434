import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, submit } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './clienteForm';
import DateFormat from '../common/dateFormat/DateFormat';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Select from '../common/form/select';




import {
	setModoTela, initForm, salvar, excluir, getListaRelatorio, setFiltro, getLista, getListaCompetencia
} from './clienteActions';

class Relatorio extends Component {

	state = {
		linhaSelecionada: null,
		periodo_inicial : DateFormat.getDataInicioAnoAtual(),
		periodo_final : DateFormat.getMesAtual(),

	}

	componentWillMount() {
		this.props.getListaRelatorio();
		this.props.getLista();
		this.props.getListaCompetencia();




	}

	render() {


	
		
		return (
			<div>

				{this.props.aguardando ? (
					<div class='modal fade show' id='modal-overlay' style={{ display: 'block', paddingRight: 15 }} aria-modal='true' role='dialog'>
						<div class='modal-dialog'>
							<div class='overlay'>
								<i class='fas fa-4x fa-spinner fa-spin' style={{ marginLeft: '50%', marginRight: '50%' }}></i>
							</div>
						</div>
					</div>
				) : null}

				<Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
				</Content>
			</div>
		)
	}

	lista() {

		let total = 0;


		return (
			<ContentCard>
				<Row>


					<Grid cols='6 6 6 6'>
						<input className='form-control'
							placeholder='Competência Inicial'
							type='text'
							value={this.state.periodo_inicial  }
							onChange={e => {
								this.setState({ ...this.state, periodo_inicial: e.target.value });
							}} />

					</Grid>

					<Grid cols='6 6 6 6'>

						<input className='form-control'
							placeholder='Competência Final'
							type='text'
							value={this.state.periodo_final  }
							onChange={e => {
								this.setState({ ...this.state, periodo_final: e.target.value });
							}} />
					</Grid>
				</Row>

				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Competência</Th>
								<Th alignRight> Valor </Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.listaRelatorio).filter(item => {
			
								if (this.state.periodo_inicial && this.state.periodo_final) {
									
									const compIniArray = this.state.periodo_inicial.split('/');
									const compIniFormatada = String(compIniArray[1]) + String(compIniArray[0]);
									const compFinArray = this.state.periodo_final.split('/');
									const compFinFormatada = String(compFinArray[1]) + String(compFinArray[0]);

						
									return (
										
										parseInt(item.competencia) >= parseInt(compIniFormatada )
										) && (

										parseInt(item.competencia) <= parseInt(compFinFormatada)
										);
								}  
								return true;
							}).map(item => {
								total += parseFloat(item.valor);
								return (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })}>
										<Td>{item.competencia_formatada}</Td>
										<Td alignRight> R$ {FormatUtils.formatarValorTela(item.valor, 2)}</Td>

									</Tr>
								)
							})}

							<Td> TOTAL</Td>
							<Td alignRight> R$ {FormatUtils.formatarValorTela(total, 2)}</Td>


						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.cliente.modoTela,
	listaRelatorio: state.cliente.listaRelatorio,
	aguardando: state.cliente.aguardando,
	filtro: state.cliente.filtro,
	lista: state.cliente.lista,
	listaCompetencia: state.cliente.listaCompetencia,

});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar, excluir, getListaRelatorio, setFiltro, getLista, getListaCompetencia
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Relatorio);