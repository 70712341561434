import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import Usuario from '../usuario/usuario';
import Cliente from '../cliente/cliente';
import Convite from '../cliente/convite';
import FinanceiroTipo from '../financeiroTipo/financeiroTipo';
import Evento from '../Evento/evento';
import Reserva from '../reserva/reserva';
import DespesaLancamento from '../depesaLancamento/despesaLancamento';
import PagamentoComissao from '../pagamentoComissao/pagamentoComissao';
import SaldoRepresentante from '../pagamentoComissao/saldoRepresentante';
import Relatorio from '../cliente/relatorio';
import Historico from '../cliente/historico';
import CadastroComissao from '../cadastroComissao/cadastroComissao';


export default props => (
	<div id='id-content-wrapper' className='content-wrapper'>
		<Switch>
			<Route exact path='/' component={Dashboard} />
			<Route path='/usuario' component={Usuario} />
			<Route path='/cliente' component={Cliente} />
			<Route path='/convite' component={Convite} />
			<Route path='/financeiroTipo' component={FinanceiroTipo} />
			<Route path='/evento' component={Evento} />
			<Route path='/reserva' component={Reserva} />
			<Route path='/despesaLancamento' component={DespesaLancamento} />
			<Route path='/pagamentoComissao' component={PagamentoComissao} />
			<Route path='/saldoRepresentante' component={SaldoRepresentante} />
			<Route path='/relatorio' component={Relatorio} />
			<Route path='/historico' component={Historico} />
			<Route path='/cadastroComissao' component={CadastroComissao} />
			<Redirect from='*' to='/' />
		</Switch>
	</div>
);
