const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaTipo: [{
		id: false,
		valor: 'Receita'
	}, {
		id: true,
		valor: 'Despesa'
	}]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'FINANCEIRO_TIPO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'FINANCEIRO_TIPO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'FINANCEIRO_TIPO_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

        default:
            return state;
    }
}