import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './reservaForm';
import DateFormat from '../common/dateFormat/DateFormat';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';

import {
	setModoTela, initForm, salvar, excluir, getListaFormaPagamento, getListaOpcoes, getLista,
	setFiltro, getListaTotal
} from './reservaActions';

class Reserva extends Component {

	state = {
		linhaSelecionada: null
	}

	componentWillMount() {
		this.props.getListaFormaPagamento();
		this.props.getListaOpcoes();
		this.props.getLista();
		this.props.getListaTotal();


	}

	render() {
		return (
			<div>

				{this.props.aguardando ? (
					<div class='modal fade show' id='modal-overlay' style={{ display: 'block', paddingRight: 15 }} aria-modal='true' role='dialog'>
						<div class='modal-dialog'>
							<div class='overlay'>
								<i class='fas fa-4x fa-spinner fa-spin' style={{ marginLeft: '50%', marginRight: '50%' }}></i>
							</div>
						</div>
					</div>
				) : null}

				<Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
				</Content>
			</div>
		)
	}

	lista() {

		let lista = this.props.lista.filter(item => {
			return !this.state.pesquisar
				|| (`${item.id_cliente_tipo == 1 ? item.numero_contrato : item.numero_convite} ${item.nome_cliente} ${item.cidade} ${item.cpf} ${item.dependente1 || ''} ${item.dependente2 || ''} ${item.dependente3 || ''} ${item.nome_vendedor} ${item.observacao}  `).toUpperCase().includes(this.state.pesquisar.toUpperCase());
		});

		return (
			<ContentCard>
				<BoxButtonHeader>
					<Button
						text='Adicionar'
						type={'success'}
						icon={'fa fa-plus'}
						style={{ position: 'fixed' }}
						event={() => {
							this.props.setModoTela('cadastro', {});
							this.props.initForm();
						}} />
				</BoxButtonHeader>

				<Row>
					{this.props.listaTotal.map(item => (
						<div class="col-lg-4 col-6" >
							<div class="small-box bg-info"  >
								<div class="inner" style={{ background: '#003060', color: '#fff' }}>
									<h3 style={{fontSize: 25}} style={{fontSize: 25}}> {item.total_reserva}</h3>
									Total Reservas
								</div>
							</div>
						</div>
					))}

					{this.props.listaTotal.map(item => (
						<div class="col-lg-4 col-6" >
							<div class="small-box bg-info"  >
								<div class="inner" style={{ background: '#055C9D', color: '#fff' }}>
									<h3 style={{fontSize: 25}}> R$ {FormatUtils.formatarValorTela(item.total_pago, 2)}</h3>
									Total Valor Pago
								</div>
							</div>
						</div>
					))}
					{this.props.listaTotal.map(item => (
						<div class="col-lg-4 col-6" >
							<div class="small-box bg-info"  >
								<div class="inner" style={{ background: '#274389', color: '#fff' }}>
									<h3 style={{fontSize: 25}}> {item.total_pessoas}</h3>
									Total Pessoas
								</div>
							</div>
						</div>
					))}
					{this.props.listaTotal.map(item => (
						<div class="col-lg-4 col-6" >
							<div class="small-box bg-info"  >
								<div class="inner" style={{ background: '#274389', color: '#fff' }}>
									<h3 style={{fontSize: 25}}> {FormatUtils.formatarValorTela(item.media_pessoas)}</h3>
									Media Pessoas
								</div>
							</div>
						</div>
					))}
					{this.props.listaTotal.map(item => (
						<div class="col-lg-4 col-6" >
							<div class="small-box bg-info"  >
								<div class="inner" style={{ background: '#274389', color: '#fff' }}>
									<h3 style={{fontSize: 25}}> R$ {FormatUtils.formatarValorTela(item.media_pago, 2)}</h3>
									Media de Valor Pago
								</div>
							</div>
						</div>
					))}
				</Row>
				<Row>
					<LabelAndInputMask
						label='Data Inicial'
						placeholder='Informe a Data Inicial'
						mask={'99/99/9999'}
						cols='12 4 3'
						value={this.props.filtro.dataInicial}
						onChange={data => {
							this.props.setFiltro({
								...this.props.filtro,
								dataInicial: data.target.value
							});
							this.props.getLista(true);
						}} />

					<LabelAndInputMask
						label='Data Final'
						placeholder='Informe a Data Final'
						mask={'99/99/9999'}
						cols='12 4 3'
						value={this.props.filtro.dataFinal}
						onChange={data => {
							this.props.setFiltro({
								...this.props.filtro,
								dataFinal: data.target.value
							});
							this.props.getLista(true);
						}} />
				
					<Grid cols='6 6 2 1'>
						<Button
							style={{ marginTop: 25 }}
							text='Atualizar'
							type={'primary'}
							icon={''}
							event={() => {
								this.props.getListaTotal({
									dataInicial: this.props.filtro.dataInicial,
 									dataFinal: this.props.filtro.dataFinal,
								});
		
							}}
							 />
							 {console.log()}
					</Grid>

					<Grid cols='12 12 12 12'>

						<input className='form-control'
							placeholder='Pesquisar'
							type='text'
							value={this.state.pesquisar}
							onChange={e => {
								this.setState({ ...this.state, pesquisar: e.target.value });
							}} />
					</Grid>
				</Row>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Cliente</Th>
								<Th alignCenter>Tipo</Th>
								<Th alignCenter>Numero</Th>
								<Th alignCenter>Vendedor</Th>
								<Th alignCenter>Cidade</Th>
								<Th alignCenter>Data Ida</Th>
								<Th alignCenter>Data Volta</Th>
								<Th alignCenter>Observação</Th>
								<Th alignCenter>Valor Pago</Th>
								<Th alignCenter>Pessoas</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td >{item.nome_cliente}</Td>
									<Td alignCenter>{!item.numero_contrato ? 'Convite' : 'Contrato'}</Td>
									<Td alignCenter>{!item.numero_contrato ? item.numero_convite : item.numero_contrato}</Td>
									<Td alignCenter>{item.nome_vendedor}</Td>
									<Td alignCenter>{item.cidade}</Td>
									<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data_ida)}</Td>
									<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data_volta)}</Td>
									<Td alignCenter>{item.observacao}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.valor_pago, 2)}</Td>
									<Td alignCenter>{item.pessoas}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fa fa-pencil'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													data_ida: DateFormat.formatarDataSqlParaTela(item.data_ida),
													data_volta: DateFormat.formatarDataSqlParaTela(item.data_volta),
													pessoas: FormatUtils.formatarValorTela(item.pessoas),
													valor_total: FormatUtils.formatarValorTela(item.valor_total || 0, 2),
													valor_pago: FormatUtils.formatarValorTela(item.valor_pago || 0, 2)
												});
												this.props.initForm({
													...item,
													data_ida: DateFormat.formatarDataSqlParaTela(item.data_ida),
													data_volta: DateFormat.formatarDataSqlParaTela(item.data_volta),
													pessoas: FormatUtils.formatarValorTela(item.pessoas),
													valor_total: FormatUtils.formatarValorTela(item.valor_total || 0, 2),
													valor_pago: FormatUtils.formatarValorTela(item.valor_pago || 0, 2)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fa fa-trash-o'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													data_ida: DateFormat.formatarDataSqlParaTela(item.data_ida),
													data_volta: DateFormat.formatarDataSqlParaTela(item.data_volta),
													pessoas: FormatUtils.formatarValorTela(item.pessoas),
													valor_total: FormatUtils.formatarValorTela(item.valor_total || 0, 2),
													valor_pago: FormatUtils.formatarValorTela(item.valor_pago || 0, 2)
												});
												this.props.initForm({
													...item,
													data_ida: DateFormat.formatarDataSqlParaTela(item.data_ida),
													data_volta: DateFormat.formatarDataSqlParaTela(item.data_volta),
													pessoas: FormatUtils.formatarValorTela(item.pessoas),
													valor_total: FormatUtils.formatarValorTela(item.valor_total || 0, 2),
													valor_pago: FormatUtils.formatarValorTela(item.valor_pago || 0, 2)
												});
											}} />
									</Td>
								</Tr>
							))}

						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.reserva.modoTela,
	lista: state.reserva.lista,
	aguardando: state.reserva.aguardando,
	filtro: state.reserva.filtro,
	listaTotal: state.reserva.listaTotal
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar, excluir, getListaFormaPagamento, getListaOpcoes, getLista,
	setFiltro, getListaTotal
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Reserva);