import React from 'react'
import Grid from '../layout/grid'

export default props => {

	if (props.onChange) {
		return (
			<Grid cols={props.cols}>
		        <div className='form-group checkbox'>
		            <label htmlFor={props.name} style={{ fontWeight: 'bold' }}>
						<input {...props}
			                 placeholder={props.placeholder}
			                 readOnly={props.readOnly}
							 type='checkbox'
							 checked={props.value == true}
							 onChange={data => props.onChange(props.value ? false : true)} />
						{` ${props.label}`}
					</label>
		        </div>
		    </Grid>
		);
	} else {
		return (
			<Grid cols={props.cols}>
				<div className='form-group checkbox'>
					<label htmlFor={props.name} style={{ fontWeight: 'bold' }}>
						<input {...props.input}
							placeholder={props.placeholder}
							readOnly={props.readOnly} type='checkbox' checked={props.input.value == true} />
						{props.label}
					</label>
				</div>
			</Grid>
		);
	}
}
