import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'CADASTRO_COMISSAO_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro,
				
			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('cadastroComissaoForm', {
			...registro,
	
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'CADASTRO_COMISSAO_SET_AGUARDANDO',
        payload: aguardando
    };
}
export function getLista() {
    const request = axios.get(`${consts.API_URL}/cadastroComissao`);
    return {
        type: 'CADASTRO_COMISSAO_LISTADO',
        payload: request
    };
}
// export function getLista(cadastroComissao) {

// 	return (dispatch, getState) => {

// 		dispatch({
// 			type: 'CADASTRO_COMISSAO_LISTADO',
// 			payload: { data: [] }
// 		});

// 		let dataInicial = DateFormat.formatarDataTelaParaParametro(getState().cadastroComissao.filtro.dataInicial);
// 		let dataFinal = DateFormat.formatarDataTelaParaParametro(getState().cadastroComissao.filtro.dataFinal);


// 		dataInicial = dataInicial && dataInicial == 'Invalid DateTime' ? null : dataInicial;
// 		dataFinal = dataFinal && dataFinal == 'Invalid DateTime' ? null : dataFinal;
		


// 		if (cadastroComissao) {
// 			axios.get(`${consts.API_URL}/cadastroComissao?
// 				data_inicial=${dataInicial}
// 				&data_final=${dataFinal}
				
// 				`)

// 			.then(resp => {
// 	            dispatch({
// 					type: 'CADASTRO_COMISSAO_LISTADO',
// 			        payload: resp
// 				});
// 	        }).catch(ex => {

// 			});
// 		} else {
// 			axios.get(`${consts.API_URL}/cadastroComissao?
// 				data_inicial=${dataInicial}
// 				&data_final=${dataFinal}

// 				`)
// 			.then(resp => {
// 	            dispatch({
// 					type: 'CADASTRO_COMISSAO_LISTADO',
// 			        payload: resp
// 				});
// 	        }).catch(ex => {

// 			});
// 		}
//     };
// }
export function getListaRepresentante() {
    const request = axios.get(`${consts.API_URL}/usuario/listarSelect`);
    return {
        type: 'CADASTRO_COMISSAO_REPRESENTANTE_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/cadastroComissao`, {
				...registro,
			
				id_usuario_alteracao: getState().auth.usuario.id,
				id_usuario_inclusao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/cadastroComissao`, {
				...registro,
			
				id_usuario_alteracao: getState().auth.usuario.id,
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/cadastroComissao?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function setFiltro(filtro) {
    console.log(filtro);
    return {
        type: 'CADASTRO_COMISSAO_FILTRO',
        payload: filtro
    };
}

export function buscarReserva(pesquisa) {
    const request = axios.get(`${consts.API_URL}/cliente/buscarReserva?pesquisa=${pesquisa}`);
    return {
        type: 'RESERVA_BUSCAR_CLIENTE_SELECT_LISTADO',
        payload: request
    };
}