import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
	return {
		type: 'PAGAMENTO_COMISSAO_MODO_TELA',
		payload: {
			modo: modo,
			registro: {
				...registro,

			}
		}
	};
}
export function initForm(registro = {}) {
	return [
		initialize('pagamentoComissaoForm', {
			...registro,

		})
	];
}

export function setAguardando(aguardando) {
	return {
		type: 'PAGAMENTO_COMISSAO_SET_AGUARDANDO',
		payload: aguardando
	};
}

export function getLista(saldoRepresentante = false) {

	return (dispatch, getState) => {

		dispatch({
			type: 'PAGAMENTO_COMISSAO_LISTADO',
			payload: { data: [] }
		});

		let dataInicial = DateFormat.formatarDataTelaParaParametro(getState().pagamentoComissao.filtro.dataInicial);
		let dataFinal = DateFormat.formatarDataTelaParaParametro(getState().pagamentoComissao.filtro.dataFinal);


		dataInicial = dataInicial && dataInicial == 'Invalid DateTime' ? null : dataInicial;
		dataFinal = dataFinal && dataFinal == 'Invalid DateTime' ? null : dataFinal;



		if (saldoRepresentante) {
			axios.get(`${consts.API_URL}/pagamentoComissao?
				data_inicial=${dataInicial}
				&data_final=${dataFinal}
				&saldoRepresentante=true
				`)

				.then(resp => {
					dispatch({
						type: 'PAGAMENTO_COMISSAO_LISTADO',
						payload: resp
					});
				}).catch(ex => {

				});
		} else {
			axios.get(`${consts.API_URL}/pagamentoComissao?
				data_inicial=${dataInicial}
				&data_final=${dataFinal}
				&saldoRepresentante=true
				`)
				.then(resp => {
					dispatch({
						type: 'PAGAMENTO_COMISSAO_LISTADO',
						payload: resp
					});
				}).catch(ex => {

				});
		}
	};
}
export function getListaRepresentante() {
	const request = axios.get(`${consts.API_URL}/usuario/listarSelect`);
	return {
		type: 'PAGAMENTO_COMISSAO_REPRESENTANTE_LISTADO',
		payload: request
	};
}

export function salvar(registro) {
	return (dispatch, getState) => {
		if (!registro.id) {
			axios.post(`${consts.API_URL}/pagamentoComissao`, {
				...registro,
				datahora_pagamento: registro.datahora_pagamento ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_pagamento) : null,
				id_usuario_alteracao: getState().auth.usuario.id,
				id_usuario_inclusao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/pagamentoComissao`, {
				...registro,
				datahora_pagamento: registro.datahora_pagamento ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_pagamento) : null,
				id_usuario_alteracao: getState().auth.usuario.id,
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
	};
}

export function excluir(registro) {
	return dispatch => {
		axios.delete(`${consts.API_URL}/pagamentoComissao?id=${registro.id}`)
			.then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
	};
}

export function setFiltro(filtro) {

	return {
		type: 'PAGAMENTO_COMISSAO_FILTRO',
		payload: filtro
	};
}

export function buscarReserva(pesquisa) {
	const request = axios.get(`${consts.API_URL}/cliente/buscarReserva?pesquisa=${pesquisa}`);
	return {
		type: 'RESERVA_BUSCAR_CLIENTE_SELECT_LISTADO',
		payload: request
	};
}
