import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Card from './layout/Card'
import { } from './dashboardActions';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from '../common/widget/valueBox'
import Row from '../common/layout/row'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

import {
    getLista, setFiltro, getListaReserva
} from './dashboardActions';


class Dashboard extends Component {


    state = {

    }

    constructor(props) {
        super(props);
    }
    componentWillMount() {
        this.props.getLista();
        this.props.getListaReserva()
    }

    render() {
        let status = this.props.filtro.status;
        let status2 = this.props.filtro.status2;
        const colors = ['#ffff','#a2b9bc', '#c62828', '#f9a825' , '#f9a825','#c62828', '#f9a825', '#f9a825', '#c62828', '#f9a825','#a2b9bc', '#f9a825', '#f9a825', '#f9a825']
        const colors2 = ['#ffff','#357c3c', '#9c27b0', ]
        for(let i = 0; i < colors.length; i = i + 1 )
        for(let i2 = 0; i2 < colors2.length; i2 = i2 + 1 )

        
           
            
            
            
            return (
                
                <Content>
                <Grid style={{ marginLeft: 15 }}>
                    <h3>
                        Dashboard
                    </h3>
                </Grid>

                <div class="col-lg-12 col-12" >
                    <label>
                        Cliente por status
                    </label>
                    <div class="small-box bg-info"  >
                        <div class="inner" style={{ background: '#fff', color: '#fff' }}>
                            <Row>
                                {this.props.lista.filter(item => {
                                   
                                    if (!status.includes(item.id_status)){
                                        return item.id_status != 4 || item.status !=5
                                        
                                    }
                                }).map(item => (
                                
                                    <div class="col-lg-4 col-6" >
                                        <div class="small-box bg-info"  >
                                            <div class="inner" style={{background:('[for]', colors[i= i + 1]), color: '#fff' }}>
                                                <h3> {FormatUtils.formatarValorTela(item.quantidade)}</h3>
                                                {item.nome_status}
                                            </div>
                                        </div>
                                    </div>  
                                )) }
                            </Row>

                        </div>

                    </div>
                </div>
                            

                <div class="col-lg-12 col-12" >
                    <label>
                        Convite por status
                    </label>
                    <div class="small-box bg-info"  >
                        <div class="inner" style={{ background: '#fff', color: '#fff' }}>
                            <Row>
                                {this.props.lista.filter(item => {
                                    
								if (!status2.includes(item.id_status)){
                                    return item.id_status != 4 || item.status !=5
                                }
							}).map(item => (
                                    <div class="col-lg-4 col-6" >
                                        <div class="small-box bg-info"  >
                                            <div class="inner" style={{ background:  ('[for]', colors2[i2= i2 + 1]), color: '#fff' }}>
                                                <h3> {item.quantidade}</h3>
                                                {item.nome_status}
                                            </div>
                                        </div>
                                    </div>
                                )) }
                            </Row>

                        </div>

                    </div>
                </div>

                <div class="col-lg-12 col-12" >
                    <label>
                       Reservas
                    </label>
                    <div class="small-box bg-info"  >
                        <div class="inner" style={{ background: '#fff', color: '#fff' }}>
                            <Row>
                            {this.props.listaReserva.map(item => (
                                    <div class="col-lg-4 col-6" >
                                        <div class="small-box bg-info"  >
                                            <div class="inner" style={{ background: '#5c4399', color: '#fff' }}>
                                            <h3> {item.abertas}</h3>
                                                Reservas Abertas 
                                            </div>
                                        </div>
                                    </div>
                                )) }
                           
                            {this.props.listaReserva.map(item => (
                                    <div class="col-lg-4 col-6" >
                                        <div class="small-box bg-info"  >
                                            <div class="inner" style={{ background: '#274389', color: '#fff' }}>
                                            <h3> {item.fechadas}</h3>
                                                Reservas Fechadas 
                                            </div>
                                        </div>
                                    </div>
                                )) }
                            </Row>

                        </div>

                    </div>

                    
                </div>

                

            </Content >
        )

    }
}
const mapStateToProps = state => ({
    sessao: state.auth.usuario,
    lista: state.dashboard.lista,
    modoTela: state.dashboard.modoTela,
    filtro: state.dashboard.filtro,
    listaReserva:state.dashboard.listaReserva,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    getLista, setFiltro, getListaReserva
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
