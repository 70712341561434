import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
    lista: [],
    listaReserva: [],
    filtro: {
        status: [4, 5],
        status2: [1, 2, 3, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16] 
     
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case 'DASHBOARD_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };

        case 'DASHBOARD_LISTADO_RESERVA':
            return { ...state,listaReserva: action.payload.data  };

        case 'DASHBOARD_FILTOR':
            return { ...state, filtro: action.payload };
        
            default:
            return state;
    }
}
