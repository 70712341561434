import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';

import { setModoTela } from './usuarioActions';

class UsuarioForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='nome'
							component={LabelAndInput}
							label='Nome *' placeholder='Informe o Nome'
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='telefone'
							component={LabelAndInput}
							label='Telefone *'
							placeholder='Informe o Telefone'
							row cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='comissao'
							component={LabelAndInputNumber}
							label='Comissão'
							placeholder='Informe a Comissão'
							row cols='12 10 6 4'
							readOnly={readOnly} 
							casas= {2} />
					</Row>
					<Row>
						<Field
							name='email'
							component={LabelAndInput}
							label='Email *'
							placeholder='Informe o Email'
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='senha'
							component={LabelAndInput}
							type="password"
							label='Senha *'
							placeholder='Informe a Senha'
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='administrativo'
							component={LabelAndCheckbox}
							label='Administrativo'
							cols='6 3 2 2'
							readOnly={readOnly} />
						<Field
							name='vendedor'
							component={LabelAndCheckbox}
							label='Vendedor'
							cols='6 3 2 2'
							readOnly={readOnly} />
					</Row>

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

UsuarioForm = reduxForm({form: 'usuarioForm', destroyOnUnmount: false})(UsuarioForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	registro: state.usuario.registro
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioForm);
