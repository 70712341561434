import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'EVENTO_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro,
				
			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('eventoForm', {
			...registro,
	
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'EVENTO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista(evento = false) {

	return (dispatch, getState) => {

		dispatch({
			type: 'EVENTO_LISTADO',
			payload: { data: [] }
		});

		let dataInicial = DateFormat.formatarDataTelaParaParametro(getState().evento.filtro.dataInicial);
		let dataFinal = DateFormat.formatarDataTelaParaParametro(getState().evento.filtro.dataFinal);


		dataInicial = dataInicial && dataInicial == 'Invalid DateTime' ? null : dataInicial;
		dataFinal = dataFinal && dataFinal == 'Invalid DateTime' ? null : dataFinal;
		


		if (evento) {
			axios.get(`${consts.API_URL}/evento?
				data_inicial=${dataInicial}
				&data_final=${dataFinal}
				`)

			.then(resp => {
	            dispatch({
					type: 'EVENTO_LISTADO',
			        payload: resp
				});
	        }).catch(ex => {

			});
		} else {
			axios.get(`${consts.API_URL}/evento?
				data_inicial=${dataInicial}
				&data_final=${dataFinal}
				`)
			.then(resp => {
	            dispatch({
					type: 'EVENTO_LISTADO',
			        payload: resp
				});
	        }).catch(ex => {

			});
		}
    };
}
export function getListaCidade() {
    const request = axios.get(`${consts.API_URL}/cidade/listarSelect`);
    return {
        type: 'EVENTO_CIDADE_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/evento`, {
				...registro,
				data_inicio: registro.data_inicio ? DateFormat.formatarDataTelaParaSql(registro.data_inicio) : null,
				data_fim: registro.data_fim ? DateFormat.formatarDataTelaParaSql(registro.data_fim) : null,
				id_usuario_alteracao: getState().auth.usuario.id,
				id_usuario_inclusao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/evento`, {
				...registro,
				data_inicio: registro.data_inicio ? DateFormat.formatarDataTelaParaSql(registro.data_inicio) : null,
				data_fim: registro.data_fim ? DateFormat.formatarDataTelaParaSql(registro.data_fim) : null,
				id_usuario_alteracao: getState().auth.usuario.id,
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/evento?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
export function setFiltro(filtro) {
    console.log(filtro);
    return {
        type: 'EVENTO_FILTRO',
        payload: filtro
    };
}

export function buscarReserva(pesquisa) {
    const request = axios.get(`${consts.API_URL}/cliente/buscarReserva?pesquisa=${pesquisa}`);
    return {
        type: 'RESERVA_BUSCAR_CLIENTE_SELECT_LISTADO',
        payload: request
    };
}
