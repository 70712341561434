const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaDespesa: [],
	listaEvento: [],
	filtro: {
		dataInicial: null,
		dataFinal: null,
	}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'DESPESA_LANCAMENTO_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'DESPESA_LANCAMENTO_LISTADO':
			return {
				...state,
				lista: action.payload.data
			};

		case 'DESPESA_LANCAMENTO_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		case 'DESPESA_LANCAMENTO_DESPESA_LISTADO':

			return {
				...state,
				listaDespesa: action.payload.data
			};
		case 'DESPESA_LANCAMENTO_EVENTO_LISTADO':

			return {
				...state,
				listaEvento: action.payload.data
			};
		case 'DESPESA_LANCAMENTO_FILTRO':
			return { ...state, filtro: action.payload };

		default:
			return state;
	}
}