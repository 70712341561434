import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './pagamentoComissaoForm';
import DateFormat from '../common/dateFormat/DateFormat';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import FormatUtils from '../common/formatUtils/FormatUtils';



import {
	setModoTela, initForm, salvar, excluir, getLista, getListaRepresentante, setFiltro,
} from './pagamentoComissaoActions';

class SaldoRepresentante extends Component {

	state = {
		linhaSelecionada: null
	}

	componentWillMount() {
		this.props.getLista();
		this.props.getListaRepresentante();

	}

	render() {
		return (
			<div>

				{this.props.aguardando ? (
					<div class='modal fade show' id='modal-overlay' style={{ display: 'block', paddingRight: 15 }} aria-modal='true' role='dialog'>
						<div class='modal-dialog'>
							<div class='overlay'>
								<i class='fas fa-4x fa-spinner fa-spin' style={{ marginLeft: '50%', marginRight: '50%' }}></i>
							</div>
						</div>
					</div>
				) : null}

				<Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
				</Content>
			</div>
		)
	}

	lista() {




		const listaUnica = [];

		(this.props.lista).map(item => {
			const nomeExiste = listaUnica.filter(nomeCliente => nomeCliente === item.nome_representante)
			if (nomeExiste.length) return;
			listaUnica.push(item.nome_representante)
		})

		const listaSoma = [];

		listaUnica.map(nomeCliente => {
			const objetoSoma = { nome_representante: nomeCliente, somaValorTitulo: 0, somaValorComissao: 0 }
			const listaNomes = (this.props.lista).filter(item => nomeCliente === item.nome_representante)
			listaNomes.map(item => {
				objetoSoma.somaValorTitulo += parseFloat(item.valor_titulo)
				objetoSoma.somaValorComissao += parseFloat(item.valor_comissao)
			})
			listaSoma.push(objetoSoma)
		})




		return (
			<ContentCard>
				<Row>

					<Grid cols='12 12 12 12'>

						<input className='form-control'
							placeholder='Pesquisar'
							type='text'
							value={this.state.pesquisar}
							onChange={e => {
								this.setState({ ...this.state, pesquisar: e.target.value });
							}} />
					</Grid>
				</Row>

				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Representante</Th>
								<Th> Valor Total de Títulos  </Th>
								<Th> Valor Total de Comissão</Th>


							</Tr>
						</THead>
						<TBody>
							{listaSoma.filter(item => {
								if (this.state.pesquisar
									&& !(`${item.nome_representante} ${item.somaValorTitulo} ${item.somaValorComissao} 
								`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome_representante}</Td>
									<Td>{FormatUtils.formatarValorTela(item.somaValorTitulo, 2)}</Td>
									<Td>{FormatUtils.formatarValorTela(item.somaValorComissao, 2)}</Td>


								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.pagamentoComissao.modoTela,
	lista: state.pagamentoComissao.lista,
	aguardando: state.pagamentoComissao.aguardando,
	filtro: state.pagamentoComissao.filtro,
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar, excluir, getLista, getListaRepresentante, setFiltro,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SaldoRepresentante);