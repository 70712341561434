import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils'

export function setModoTela(modo, registro = {}) {
    return {
        type: 'USUARIO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('usuarioForm', {
			...registro,
			comissao : FormatUtils.formatarValorTela(registro.comissao, 2)
		}),
		
		
    ];
}

export function getLista(id_empresa) {
    const request = axios.get(`${consts.API_URL}/usuario?inativos=true`);
    return {
        type: 'USUARIO_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
	if (!registro.id) {
	    return (dispatch, getState) => {
	        axios.post(`${consts.API_URL}/usuario`, {
				...registro,
				comissao : FormatUtils.formatarValorBanco(registro.comissao, 2)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
	    };
	} else {
	    return (dispatch, getState) => {
	        axios.put(`${consts.API_URL}/usuario`, {
				...registro,
				comissao : FormatUtils.formatarValorTela(registro.comissao, 2)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
	    };
	}
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/usuario?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function reativar(registro) {
	return (dispatch, getState) => {
	    axios.put(`${consts.API_URL}/usuario/reativar`, {
			...registro
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
	};
}