import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ColorSelector from '../common/form/colorSeletor';

import {
	setModoTela, initForm
} from './despesaLancamentoActions';
import { selectTab } from '../common/tab/tabActions';
import labelAndInput from '../common/form/labelAndInput';
import labelAndInputDateTime from '../common/form/labelAndInputDateTime';

class DespesaLancamentoForm extends Component {

	state = {

	}

	componentWillMount() {

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';


		console.log(this.props.listaDespesa);
		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>

							<Select
								name='id_despesa'
								component={LabelAndInput}
								label='Despesa'
								placeholder='Informe o vendedor'
								cols='12 6 6 4'
								readOnly={readOnly}
								options={this.props.listaDespesa}
								value={this.props.formularioValues.id_despesa}
								onChange={(value) => {
									this.props.initForm({
										...this.props.formularioValues,
										id_despesa: value
									})
								}} />
						</Row>
						<Row>

							<Select
								name='id_evento'
								component={LabelAndInput}
								label='Evento'
								placeholder='Informe o evento'
								cols='12 6 6 4'
								readOnly={readOnly}
								options={this.props.listaEvento}
								value={this.props.formularioValues.id_evento}
								onChange={(value) => {
									this.props.initForm({
										...this.props.formularioValues,
										id_evento: value
									})
								}} />
						</Row>
						<Row>
							<Field
								name='valor'
								component={LabelAndInputNumber}
								label='Valor'
								placeholder='Informe o valor'
								cols='12 6 4 3'
								readOnly={readOnly} />
						</Row>

						<Row>
							<Field
								name='observacao'
								component={labelAndInput}
								label='Observação'
								placeholder='Informe a observeção'
								cols='12 6 4 3'
								readOnly={readOnly} />
						</Row>


						<Row>
							<Field
								name='datahora_despesa'
								component={labelAndInputDateTime}
								label='Data Despesa'
								placeholder='Informe a data'
								cols='12 6 6 6'

								readOnly={readOnly} />
						</Row>





					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		)
	}


}

DespesaLancamentoForm = reduxForm({ form: 'despesaLancamentoForm', destroyOnUnmount: false })(DespesaLancamentoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('despesaLancamentoForm')(state),
	registro: state.despesaLancamento.registro,
	listaDespesa: state.despesaLancamento.listaDespesa,
	listaEvento: state.despesaLancamento.listaEvento,

});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DespesaLancamentoForm);
