import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'RESERVA_MODO_TELA',
        payload: {
            modo: modo,
            registro: {
                ...registro,

            }
        }
    };
}
export function initForm(registro = {}) {
    return [
        initialize('reservaForm', {
            ...registro
        })
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'RESERVA_SET_AGUARDANDO',
        payload: aguardando
    };
}


export function salvar(registro) {
    console.log(registro);
    return (dispatch, getState) => {
        if (!registro.id) {
            axios.post(`${consts.API_URL}/reserva`, {
                ...registro,
                data_ida: DateFormat.formatarDataTelaParaSql(registro.data_ida),
                data_volta: DateFormat.formatarDataTelaParaSql(registro.data_volta),
                pessoas: FormatUtils.formatarValorBanco(registro.pessoas),
                valor_total: FormatUtils.formatarValorBanco(registro.valor_total, 2),
                valor_pago: FormatUtils.formatarValorBanco(registro.valor_pago, 2),
                id_usuario_alteracao: getState().auth.usuario.id,
                id_usuario_inclusao: getState().auth.usuario.id
            }).then(resp => {
                setSuccess('Operação Realizada com sucesso.');
                dispatch(getLista());
                dispatch(setModoTela('lista'));
            }).catch(e => {
                setErroAPI(e);
            });
        } else {
            axios.put(`${consts.API_URL}/reserva`, {
                ...registro,
                data_ida: DateFormat.formatarDataTelaParaSql(registro.data_ida),
                data_volta: DateFormat.formatarDataTelaParaSql(registro.data_volta),
                pessoas: FormatUtils.formatarValorBanco(registro.pessoas),
                valor_total: FormatUtils.formatarValorBanco(registro.valor_total, 2),
                valor_pago: FormatUtils.formatarValorBanco(registro.valor_pago, 2),
                id_usuario_alteracao: getState().auth.usuario.id,
            }).then(resp => {
                setSuccess('Operação Realizada com sucesso.');
                dispatch(getLista());
                dispatch(setModoTela('lista'));
            }).catch(e => {
                setErroAPI(e);
            });
        }
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/reserva?id=${registro.id}`)
            .then(resp => {
                setSuccess('Operação Realizada com sucesso.');
                dispatch(getLista());
                dispatch(setModoTela('lista'));
            }).catch(e => {
                setErroAPI(e);
            });
    };
}



export function getListaFormaPagamento() {
    const request = axios.get(`${consts.API_URL}/formaPagamento/listarSelect`);
    return {
        type: 'RESERVA_FORMA_PAGAMENTO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaOpcoes() {
    const request = axios.get(`${consts.API_URL}/opcoesViagem/listarSelect`);
    return {
        type: 'RESERVA_OPCOES_VIAGEM_SELECT_LISTADO',
        payload: request
    };
}

export function buscarReserva(pesquisa) {
    const request = axios.get(`${consts.API_URL}/cliente/buscarReserva?pesquisa=${pesquisa}`);
    return {
        type: 'RESERVA_BUSCAR_CLIENTE_SELECT_LISTADO',
        payload: request
    };
}

export function getLista(reserva = false) {

	return (dispatch, getState) => {

		dispatch({
			type: 'RESERVA_LISTADO',
			payload: { data: [] }
		});

		let dataInicial = DateFormat.formatarDataTelaParaParametro(getState().reserva.filtro.dataInicial);
		let dataFinal = DateFormat.formatarDataTelaParaParametro(getState().reserva.filtro.dataFinal);


		dataInicial = dataInicial && dataInicial == 'Invalid DateTime' ? null : dataInicial;
		dataFinal = dataFinal && dataFinal == 'Invalid DateTime' ? null : dataFinal;
		


		if (reserva) {
			axios.get(`${consts.API_URL}/reserva?
				data_inicial=${dataInicial}
				&data_final=${dataFinal}
				`)

			.then(resp => {
	            dispatch({
					type: 'RESERVA_LISTADO',
			        payload: resp
				});
	        }).catch(ex => {

			});
		} else {
			axios.get(`${consts.API_URL}/reserva?
				data_inicial=${dataInicial}
				&data_final=${dataFinal}
				`)
			.then(resp => {
	            dispatch({
					type: 'RESERVA_LISTADO',
			        payload: resp
				});
	        }).catch(ex => {

			});
		}
    };
}
export function setFiltro(filtro) {
    console.log(filtro);
    return {
        type: 'RESERVA_FILTRO',
        payload: filtro
    };
}

// export function getListaTotal() {
//     const request = axios.get(`${consts.API_URL}/reserva/listarTotal`)
//     return {
//         type: 'RESERVA_TOTAL_LISTADO',
//         payload: request
//     };
// }

export function getListaTotal(reserva = false) {

	return (dispatch, getState) => {

		dispatch({
			type: 'RESERVA_TOTAL_LISTADO',
			payload: { data: [] }
		});

		let dataInicial = DateFormat.formatarDataTelaParaParametro(getState().reserva.filtro.dataInicial);
		let dataFinal = DateFormat.formatarDataTelaParaParametro(getState().reserva.filtro.dataFinal);


		dataInicial = dataInicial && dataInicial == 'Invalid DateTime' ? null : dataInicial;
		dataFinal = dataFinal && dataFinal == 'Invalid DateTime' ? null : dataFinal;
		


		if (reserva) {
			axios.get(`${consts.API_URL}/reserva/listarTotal?
				data_inicial=${dataInicial}
				&data_final=${dataFinal}
				`)

			.then(resp => {
	            dispatch({
					type: 'RESERVA_TOTAL_LISTADO',
			        payload: resp
				});
	        }).catch(ex => {

			});
		} else {
			axios.get(`${consts.API_URL}/reserva/listarTotal?
				data_inicial=${dataInicial}
				&data_final=${dataFinal}
				`)
			.then(resp => {
	            dispatch({
					type: 'RESERVA_TOTAL_LISTADO',
			        payload: resp
				});
	        }).catch(ex => {

			});
		}
    };
}