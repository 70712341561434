import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, submit } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './clienteForm';
import DateFormat from '../common/dateFormat/DateFormat';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Select from '../common/form/select';

import {
	setModoTela, initForm, salvar, excluir, getListaMensagemHistorico, setFiltro, getLista
} from './clienteActions';

class Historico extends Component {

	state = {
		linhaSelecionada: null,


	}

	componentWillMount() {
		this.props.getListaMensagemHistorico();
		this.props.getLista();
	}

	render() {


		return (
			<div>

				{this.props.aguardando ? (
					<div class='modal fade show' id='modal-overlay' style={{ display: 'block', paddingRight: 15 }} aria-modal='true' role='dialog'>
						<div class='modal-dialog'>
							<div class='overlay'>
								<i class='fas fa-4x fa-spinner fa-spin' style={{ marginLeft: '50%', marginRight: '50%' }}></i>
							</div>
						</div>
					</div>
				) : null}

				<Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
				</Content>
			</div>
		)
	}

	lista() {


		return (
			<ContentCard>
				<Row>
					<LabelAndInputMask
						label='Data Inicial' placeholder='Informe o período inicial'
						cols='12 6 3 2'
						mask='99/99/9999'
						value={this.props.filtro.data_inicial}
						onChange={(data) => {
							this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
							this.props.getLista();
						}} />

					<LabelAndInputMask
						label='Data Final' placeholder='Informe o período final'
						cols='12 6 3 2'
						mask='99/99/9999'
						value={this.props.filtro.data_final}
						onChange={(data) => {
							this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
							this.props.getLista();
						}} />
				</Row>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Historico</Th>

							</Tr>
						</THead>
						<TBody>

							{(this.props.listaHistorico).map(item => {
								let valorColuna = [];
								return (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })}>
										{item.texto.split('\n').map((texto, index) => {
												if (index > 0 && (index + 1) % 3 === 0) {

													const divColuna = <Td>{valorColuna}</Td>;
													valorColuna = [<p>{texto}</p>];
													return divColuna;

												} valorColuna.push(<p>{texto}</p> )
											})}		
												{/* {item.texto.split('\n').map((texto, index) => (
												<p>{texto}</p> 
												))}	 */}
									</Tr>
								)
							})}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.cliente.modoTela,
	listaHistorico: state.cliente.listaHistorico,
	aguardando: state.cliente.aguardando,
	filtro: state.cliente.filtro,
	lista: state.cliente.lista,


});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar, excluir, getListaMensagemHistorico, setFiltro, getLista
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Historico);