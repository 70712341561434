const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	filtro: {
		dataInicial: null,
		dataFinal: null,
	},
    listaRepresentante: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'CADASTRO_COMISSAO_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'CADASTRO_COMISSAO_LISTADO':
			return {
				...state,
				lista: action.payload.data
			};

		case 'CADASTRO_COMISSAO_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		case 'CADASTRO_COMISSAO_REPRESENTANTE_LISTADO':

			return {
				...state,
				listaRepresentante: action.payload.data
			};

		case 'CADASTRO_COMISSAO_FILTRO':
			return { ...state, filtro: action.payload };

		default:
			return state;
	}
}