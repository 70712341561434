import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import Button from '../common/button/button';
import Form from './usuarioForm';
import FormatUtils from '../common/formatUtils/FormatUtils'

import { setModoTela, initForm, salvar, excluir, getLista, reativar } from './usuarioActions';

class Usuario extends Component {

    componentWillMount() {
        this.props.getLista();
    }

    render() {
        return (
            <div>
                <ContentHeader title='Usuário' small='Cadastro' />
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<BoxButtonHeader>
					<Button
						text='Adicionar'
						type={'success'}
						icon={'fa fa-plus'}
						style={{ position: 'fixed' }}
						event={() => {
							this.props.setModoTela('cadastro', {});
							this.props.initForm();
						}} />
				</BoxButtonHeader>
				<Table>
					<THead>
						<Tr>
							<Th>Nome</Th>
							<Th alignCenter>Email</Th>
							<Th alignCenter>Telefone</Th>
							<Th alignCenter>Comissão</Th>
							<Th alignCenter>Administrativo</Th>
							<Th alignCenter>Vendedor</Th>
							<Th alignCenter>Ativo</Th>
							<Th alignCenter acoes>Ações</Th>
						</Tr>
					</THead>
					<TBody>
						{(this.props.lista).map(item => (
							<Tr key={item.id}>
								<Td>{item.nome}</Td>
								<Td alignCenter>{item.email}</Td>
								<Td alignCenter>{item.telefone}</Td>
								<Td alignCenter>{FormatUtils.formatarValorTela(item.comissao, 2)}</Td>
								<Td alignCenter>{item.administrativo ? 'Sim' : 'Não'}</Td>
								<Td alignCenter>{item.vendedor ? 'Sim' : 'Não'}</Td>
								<Td alignCenter title={item.ativo ? 'Inativar' : 'Reativar'}>
									<ButtonTable
										type={item.ativo ? 'success' : 'danger'}
										icon={item.ativo ? 'fa fa-check' : 'fa fa-ban'}
										title={item.ativo ? 'Inativar' : 'Reativar'}
										event={() => {
											if (item.ativo) {
												this.props.excluir(item);
											} else {
												this.props.reativar(item);
											}
										}} />
								</Td>
								<Td alignCenter>
									<ButtonTable
										type={'warning'}
										icon={'fa fa-pencil'}
										event={() => {
											this.props.setModoTela('cadastro', {
												...item,
												senha: null
											});
											this.props.initForm({
												...item,
												senha: null
											});
										}} />
									{/*<ButtonTable
										type={'danger'}
										icon={'fa fa-trash-o'}
										event={() => {
											this.props.setModoTela('exclusao', {
												...item,
												senha: null
											});
											this.props.initForm({
												...item,
												senha: null
											});
										}} />*/}
								</Td>
							</Tr>
						))}
					</TBody>
				</Table>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.usuario.modoTela,
	lista: state.usuario.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, reativar }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Usuario);
